import React, {useState} from 'react';

function SiteNavbar () {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <nav className="flex items-center justify-end flex-wrap p-6 bg-main-site-bg">
            <div className="block md:hidden">
                <button onClick={() => setIsOpen(!isOpen)} className="inline-flex items-center justify-center p-2 w-10 h-10 text-sm text-main-site-white rounded-lg md:hidden hover:bg-main-site-orange focus:outline-none focus:ring-0" aria-controls="navbar-default" aria-expanded="false">
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                    </svg>
                </button>
            </div>
            <div className={`w-full block md:flex justify-end ${isOpen ? "block" : "hidden"}`}>
                <ul className="font-medium flex flex-col p-4 bg-main-site-bg md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0">
                    <li>
                        <a href="https://www.drengland.co.uk" className="block py-2 px-3 text-main-site-orange hover:text-main-site-orange bg-main-site-bg rounded md:p-0" aria-current="page">Home</a>
                    </li>
                    <li>
                        <a href="https://www.drengland.co.uk" className="block py-2 px-3 text-main-site-white hover:text-main-site-orange bg-main-site-bg rounded md:p-0" aria-current="page">About</a>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default SiteNavbar;
import React, {useState, useEffect} from 'react';
import './styles/main.css';
import Session from './Session';
import MakeApiCall from './components/Api';
import SiteNavbar from './components/navbar';
import HomeBody from './components/homebody';

function App() {
  // Create Session
  const [sessionJwt, setSessionJwt] = useState(null);
  const [sessionActive, setSessionActive] = useState(false);
  const sessionApiKey = process.env.REACT_APP_API_KEY;
  
  useEffect( () => {
    if (!sessionActive) {
      MakeApiCall({
        endpoint: 'create-session',
        sessionApiKey: sessionApiKey,
        setSessionActive: setSessionActive,
        sessionJwt: sessionJwt,
        setSessionJwt: setSessionJwt
      });
    }
  }, [sessionJwt, sessionActive, sessionApiKey]);

  return (
    <Session.Provider value={ {sessionJwt, sessionActive} }>
      <div>
        {sessionActive
          ?
            <div className="App">
              <SiteNavbar />
              <HomeBody />
            </div>
          :
            <div>
              <p>Loading...</p>
            </div>
        }
      </div>
    </Session.Provider>
  )
}

export default App;

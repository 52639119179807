import React, {useState, useEffect, useContext} from 'react';
import MakeApiCall from './Api';
import Session from '../Session';

function HomeBody () {
    const [currentTime, setCurrentTime] = useState(null);
    const { sessionJwt } = useContext(Session);
    useEffect( () => {
        MakeApiCall({
            endpoint: 'time',
            sessionJwt: sessionJwt,
            setCurrentTime: setCurrentTime
        });
    }, [sessionJwt]);

    return (
        <div className="App-header">
            { currentTime ? currentTime : "Loading..." }
            <p>Environment: {process.env.NODE_ENV}</p>
            <p>API Endpoint: {process.env.REACT_APP_API_ENDPOINT}</p>
        </div>
    )
}

export default HomeBody;
function MakeApiCall(params) {
    let requestOptions = {
        method: '',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${params.sessionJwt}`
        }
    }
    switch(params.endpoint){
        case 'create-session':
            requestOptions["method"] = "POST";
            requestOptions["body"] = JSON.stringify({ "sessionApiKey": params.sessionApiKey});
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/create-session`, requestOptions).then(res => res.json()).then(data => {
                if (data.access_token) {
                    params.setSessionJwt(data.access_token);
                    params.setSessionActive(true);
                }
            });
            break;
        case 'time':
            requestOptions["method"] = "GET";
            fetch(`${process.env.REACT_APP_API_ENDPOINT}/time`, requestOptions).then(res => res.json()).then(data => {
                params.setCurrentTime(data.time);
            });
            break;
        default:
            break;
    }
};

export default MakeApiCall;